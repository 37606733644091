import { useState, useContext, useEffect } from "react";
import {
  TabContent,
  TabPane,
  ButtonGroup,
  Button,
  Row,
  Col,
  ListGroupItem,
  Collapse,
} from "reactstrap";
import { v4 as uuid } from "uuid";

import distance from "@turf/distance";
import destination from "@turf/destination";
import flip from "@turf/flip";
import { point } from "@turf/helpers";

import { toPng } from "html-to-image";
import jsPDF from "jspdf";
import { Scrollbars } from "react-custom-scrollbars-2";
import FormSwitcher from "./FormSwitcher";
import WarningModal from "./WarningModal";
import { ToolContext } from "../context/ToolContext";
import { FormContext } from "../context/FormContext";
import { checkAdmdConsumerTypes } from "../utils/admdFunctions";
import { isEmpty } from "../utils/isEmpty";
import { resetFileInput } from "../utils/resetFileInput";
import {
  postAssessments,
  loadFile,
  loadFiles,
  loadTransformers,
  getStudyFilters,
  saveNetwork,
  deleteNetwork,
  archiveNetwork,
  updateNetwork,
  translateWinDebutToStudy,
  getSpecificReference,
  getDefaultReference,
  translateXmlToStudy,
  translateConfigToUI,
} from "../services/apiService";
import {
  cleanseDownstreamFeederNumbers,
  setNewDefaults,
} from "../services/historicalDataCleanserService";
import { setConstraints } from "../services/constraintsService";
import { createNetworkFromAssessmentResponse } from "../model/viewModel/networkFactory";
import { createCimNetwork } from "../model/cim/cimFactory";
import NetworkLoad from "./NetworkLoad";
import FaultFilter from "./FaultFilter";
import Searchbox from "./Searchbox";
import MapSearch from "./MapSearch";
import SortList from "./SortList";
import Detect from "./Detect";
import ShowNearbyAssetToggle from "./ShowNearbyAssetToggle";
import { checkForDefaults } from "../utils/checkForDefaults";
import { hasOverrides } from "../utils/configurationFunctions";
import { useMsal } from "@azure/msal-react";
import FeederFilter from "./FeederFilter";
import { stringToBoolean } from "../utils/stringToBoolean";
import SavedStudies from "./SavedStudies";
import SavedStudiesFilter from "./SavedStudiesFilter";
import Crown from "./Crown";
import {
  checkOverheadLineLength,
  checkCableHasGroundTypeOverrides,
  checkCableLengthMatchesGroundTypeOverrides,
  getWarningMessage,
} from "../utils/cableFunctions";
import {
  isRagNetworkPopulated,
  centreNetworkOnMap,
  resetNetworkToolState,
  detectAndConfigureRating,
} from "../utils/networkFunctions";
import { checkDesignVoltage } from "../utils/transformerFunctions";
import { setBrowserTabTitle } from "../utils/browserFunctions";
import { getFileManagementSortItems } from "../utils/sortFunctions";
import { validateLinkBoxCables } from "../utils/linkBoxFunctions";
import { splitFilename, validateFiles } from "../utils/processFiles";
import * as tab from "../constants/tabs";
import * as alert from "../constants/alert";
import * as cable from "../constants/cable";
import SearchFile from "./search-file/search-file";
import Spinner from "./Spinner";
import { useLocalStorage } from "../utils/useLocalStorage";
import { useDispatch, useSelector } from "react-redux";
import * as PDFJS from "pdfjs-dist";
import * as PDFJSWorker from "../utils/pdf.worker";
import * as statusConstants from "../constants/status";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import AssetSearch from "./AssetSearch";
import {
  checkReduxNetworkPopulated,
  clearResults,
  findAll,
  resolveAllDefaults,
  replaceWelders,
  replaceMotors,
  replacePointOfConnections,
  replaceTransformers,
  replaceGroupedConnections,
  replaceNodes,
  replaceConnectionPoints,
  replaceCables,
  buildNetwork,
  setAsSaved,
  setTitle,
  setHideMap,
} from "../app/networkSlice";
import { getResultProperties } from "../utils/referenceFunctions";
import IconRadioGrp from "./IconRadioGrp";
import { store } from "../app/store";
import { clearHistoryAction, endBatchAction, startBatchAction } from "../app/undoable";
import {
  upgradeCableTypes,
  upgradeConsumers,
  upgradeFeederFuses,
  upgradeGroundTypes,
  upgradeNodeFuses,
  upgradeTransformers,
} from "../services/upgradeNetworkService";
import ConfigurationSelection from "./ConfigurationSelection";
import useBearerToken from "../utils/useBearerToken";
import { validationRules } from "../constants/importValidationRules";
import { SUSTAINED } from "../constants/ratings";

const removeTransformPathLayers = (leafletMap) => {
  leafletMap.eachLayer(function (layer) {
    if (layer.transform && layer.transform._handlersGroup) {
      const transformLayers = Object.values(layer.transform._handlersGroup._layers).map((l) => l);
      transformLayers.forEach((lyr) => {
        leafletMap.removeLayer(lyr);
      });
    }
  });
};

const { REACT_APP_THEME, REACT_APP_B2C, REACT_APP_B2B } = process.env;
const MODE_SELECT = "select";
const REFRESH_TAB_DELAY = 0.001;

const ControlTabs = () => {
  const { instance, accounts } = useMsal();
  const getToken = useBearerToken();

  useEffect(() => {
    toggle(clientSettings.Features.CROWNEnabled ? tab.CROWN : tab.FILE);
  }, []);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      const _toolState = { ...toolState };
      _toolState.activeTab = tab;
      setToolState(_toolState);
    }
  };

  const [fileName, setFileName] = useState("");
  const [userNameInput, setUserNameInput] = useState("");
  const [area, setArea] = useState("");
  const [group, setGroup] = useState("");
  const [fileToLoad, setFileToLoad] = useState(false);
  const [fileToImport, setFileToImport] = useState(null);
  const [fileToImportType, setFileToImportType] = useState(null);
  const [winDebutFileToImport, setWinDebutFileToImport] = useState(null);
  const [fileNameConflict, setFileNameConflict] = useState(false);
  const [loadedNetwork, setLoadedNetwork] = useState("");
  const [loadedFiles, setLoadedFiles] = useState(null);
  const [loadedTransformers, setLoadedTransformers] = useState([]);
  const [studyFilters, setStudyFilters] = useState(null);
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [faults, setFaults] = useState([]);
  const [fileToDelete, setFileToDelete] = useState(false);
  const [fileToArchive, setFileToArchive] = useState(false);
  const [archiveFile, setArchiveFile] = useState(false);
  const [overwriteStudy, setOverwriteStudy] = useState(false);
  const [hasDefaults, setHasDefaults] = useState(false);
  const [defaultsConfirmed, setDefaultsConfirmed] = useState(false);
  const [studyPeriod, setStudyPeriod] = useState("recent");
  const [whosStudy, setWhosStudy] = useState("myStudies");
  const [selectedGroup, setSelectedGroup] = useState("");
  const [selectedArea, setSelectedArea] = useState("");
  const [selectedStudyId, setSelectedStudyId] = useState("");
  const [sortByCustom, setSortByCustom] = useState("studyCreatedDate");
  const [sortByAscDesc, setSortByAscDesc] = useState("descending");
  const { formState, dispatch } = useContext(FormContext);
  const { toolState, setToolState } = useContext(ToolContext);
  const [isLoadedRAGPreventingLoad, setIsLoadedRAGPreventingLoad] = useState(false);
  const [isLoadedRAGPreventingImport, setIsLoadedRAGPreventingImport] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isArchiving, setIsArchiving] = useState(null);
  const [isDeleting, setIsDeleting] = useState(null);
  const [isLoadingNetwork, setIsLoadingNetwork] = useState(false);
  const [areStudiesLoaded, setAreStudiesLoaded] = useState(false);
  const [multiPagePDF, setMultiPagePDF] = useState(false);
  const [importError, setImportError] = useState(undefined);
  const [winDebutImportError, setWinDebutImportError] = useState(undefined);
  const [winDebutConfiguration, setWinDebutConfiguration] = useState(undefined);
  const [mismatchedWinDebutFiles, setMismatchedWinDebutFiles] = useState(undefined);
  const [invalidConfig, setInvalidConfig] = useState(undefined);
  const [discardingOverrides, setDiscardingOverrides] = useState(false);
  const [upgradeConfig, setUpgradeConfig] = useState(undefined);
  const [upgradeConfigWarnings, setUpgradeConfigWarnings] = useState([]);

  const allAssets = useSelector((state) => findAll(state));
  const welders = useSelector((state) => state.network.present.welders);
  const motors = useSelector((state) => state.network.present.motors);
  const pointOfConnections = useSelector((state) => state.network.present.pointOfConnections);
  const transformers = useSelector((state) => state.network.present.transformers);
  const groupedConnections = useSelector((state) => state.network.present.groupedConnections);
  const nodes = useSelector((state) => state.network.present.nodes);
  const connectionPoints = useSelector((state) => state.network.present.connectionPoints);
  const cables = useSelector((state) => state.network.present.cables);
  const builtNetwork = useSelector((state) => buildNetwork(state));

  const dispatchRedux = useDispatch();

  // Use values from Local Storage...
  const [isMapSearchOpen, setIsMapSearchOpen] = useLocalStorage("isMapSearchOpen", false);
  const [isImportNetworkOpen, setIsImportNetworkOpen] = useLocalStorage(
    "isImportNetworkOpen",
    false,
  );
  const [isImportSidePlanPdfOpen, setIsImportSitePlanPdfOpen] = useLocalStorage(
    "isImportSitePlanPdfOpen",
    false,
  );
  const [isImportStudyOpen, setIsImportStudyOpen] = useLocalStorage("isImportStudyOpen", false);
  const [isImportWinDebutStudyOpen, setIsImportWinDebutStudyOpen] = useLocalStorage(
    "isImportWinDebutStudyOpen",
    false,
  );
  const [isSaveStudyOpen, setIsSaveStudyOpen] = useLocalStorage("isSaveStudyOpen", false);
  const [isLoadStudyOpen, setIsLoadStudyOpen] = useLocalStorage("isLoadStudyOpen", true);

  let filterResults = [];

  if (formState && formState.clientSettings && formState.clientSettings.filterResults) {
    filterResults = [...formState.clientSettings.filterResults];
  }

  const { assessmentRunning, activeTab, showResults, leafletMap, sitePlans, toolbar } = toolState;
  const { network, reference, clientSettings, localOverrideConfig, rawConfigCompressed } =
    formState;

  const ratingType = useSelector((state) => state.settings.ratingType);
  const popupResults = useSelector((state) => state.settings.isPopupResultsEnabled);
  const networkChanged = useSelector((state) => state.network.present.networkChanged);
  const isReduxNetworkPopulated = useSelector((state) => checkReduxNetworkPopulated(state));

  useEffect(() => {
    if (
      !isRagNetworkPopulated(formState.ragNetworks) &&
      !isReduxNetworkPopulated &&
      !networkChanged
    ) {
      setSelectedStudyId("");
      setFileName("");
      clientSettings.Features.StudyBrowserTitleEnabled &&
        setBrowserTabTitle(clientSettings.General.DisplayName);
    }
  }, [networkChanged, formState.ragNetworks]);

  const setToolStateProp = (prop, value) => {
    const _toolState = toolState;
    _toolState[prop] = {
      type: "warning",
      className: "warning",
      messages: value,
    };
    setToolState(_toolState);
  };

  const hideResults = () => {
    const _toolState = toolState;
    _toolState.showResults = false;
    setToolState(_toolState);
    dispatchRedux(clearResults(getResultProperties(clientSettings)));
  };

  const _loadFiles = async () => {
    setAreStudiesLoaded(false);
    setLoadedFiles(null);
    const token = await getToken(instance, accounts);
    const response = await loadFiles(token);
    setLoadedFiles(response);
    setAreStudiesLoaded(true);
  };

  const _loadTransformers = async () => {
    const token = await getToken(instance, accounts);
    const response = await loadTransformers(token);
    setLoadedTransformers(response);
  };

  const updateLoadedStudies = (studyIdToRemove, studyItemToAdd) => {
    var newStudies = loadedFiles.studies.filter((p) => p.studyId !== studyIdToRemove);
    if (studyItemToAdd) {
      newStudies.splice(0, 0, studyItemToAdd);
    }
    setLoadedFiles({ studies: newStudies });
  };

  const getFilteredFiles = () => {
    if (loadedFiles && loadedFiles.studies) {
      let studies = loadedFiles.studies.filter(
        (file) =>
          (selectedGroup === "" || file.groupId === selectedGroup) &&
          (selectedArea === "" || file.areaId === selectedArea) &&
          file.studyArchived !== (studyPeriod === "recent"),
      );
      return studies.sort(function (a, b) {
        const sortAscending = () => {
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        };
        const sortDescending = () => {
          return textA > textB ? -1 : textA < textB ? 1 : 0;
        };
        var textA = a[sortByCustom].toUpperCase();
        var textB = b[sortByCustom].toUpperCase();
        return sortByAscDesc === "ascending" ? sortAscending() : sortDescending();
      });
    }
    if (!loadedFiles) {
      return undefined;
    }
    return [];
  };

  const getSelectedStudy = (studyId) => {
    if (loadedFiles && loadedFiles.studies) {
      return loadedFiles.studies.find((study) => study.studyId === studyId);
    }
  };

  const getStudyByFileName = (studyName) => {
    if (loadedFiles && loadedFiles.studies) {
      return loadedFiles.studies.find(
        (study) => study.studyName.toLowerCase() === studyName.toLowerCase(),
      );
    }

    return {};
  };

  const _loadStudyFilters = async () => {
    const token = await getToken(instance, accounts);
    const response = await getStudyFilters(token);

    setStudyFilters(response);

    if (response && response.filters) {
      if (response.filters.Groups && response.filters.Groups.length === 1) {
        setSelectedGroup(response.filters.Groups[0].id);
        setGroup(response.filters.Groups[0].id);
      }

      if (response.filters.Areas && response.filters.Areas.length === 1) {
        setSelectedArea(response.filters.Areas[0].id);
        setArea(response.filters.Areas[0].id);
      }
    }
  };

  const loadOrImportNetwork = (studyId) => {
    const _toolState = toolState;
    _toolState.showResults = false;
    _toolState.errors = {};
    setToolState(_toolState);
    dispatchRedux(clearResults(getResultProperties(clientSettings)));

    if (studyId) loadNetwork(studyId);
    else importNetwork();

    setFileToLoad(false);
    setIsLoadedRAGPreventingLoad(false);
    setSelectedStudyId("");
    setDefaultsConfirmed(false);
    if (studyId) {
      isRagNetworkPopulated(formState.ragNetworks) && replaceRagNetworksState();
    }
    refreshFileTab();
  };

  const refreshFileTab = () => {
    document.getElementById("btnPropertiesTab").click();
    setTimeout(() => {
      document.getElementById("btnFileTab").click();
    }, REFRESH_TAB_DELAY);
  };

  const loadNetwork = async (studyId) => {
    setIsLoadingNetwork(true);
    document.getElementById("displayedInput").value = "";
    document.getElementById("loadNetwork").value = "";
    const _toolState = toolState;
    resetNetworkToolState(_toolState);
    _toolState.showNearbyAssets.substations = false;
    setToolState(_toolState);

    const substationSearchElement = document.getElementById("substation-search");
    if (substationSearchElement) {
      substationSearchElement.value = "";
    }

    const locationSearchElement = document.getElementById("location-search");
    if (locationSearchElement) {
      locationSearchElement.value = "";
    }

    const token = await getToken(instance, accounts);
    const response = await loadFile(token, studyId);
    const network = JSON.parse(response.studyContent);
    const sitePlans = response.sitePlans ? JSON.parse(response.sitePlans) : [];

    if (network === null) {
      setIsLoadingNetwork(false);
      return;
    }

    const study = getSelectedStudy(studyId);

    if (study) {
      setFileName(study.studyName);
      setGroup(study.groupId);
      setArea(study.areaId);
    }

    removeTransformPathLayers(leafletMap);
    cleanseDownstreamFeederNumbers(network);

    loadNetworkWithConfig(network).then(() => {
      replaceSitePlans(sitePlans);
      setLoadedNetwork(studyId);
      dispatchRedux(setAsSaved());
      setIsLoadingNetwork(false);

      clientSettings.Features.StudyBrowserTitleEnabled &&
        setBrowserTabTitle(clientSettings.General.DisplayName, study.studyName);
      dispatchRedux(setTitle(study.studyName));
    });
  };

  const loadNetworkWithConfig = async (network) => {
    const token = await getToken(instance, accounts);
    return new Promise((resolve) => {
      const loadNetwork = (newReference) => {
        const allNewGeometries = replaceNetworkState(network, true, newReference);
        centreNetworkOnMap(network, allNewGeometries, leafletMap);
        detectAndConfigureRating(network, dispatchRedux);
      };

      const canManageConfigs =
        clientSettings.Features.SelectOnlyClientConfigEnabled ||
        clientSettings.Features.SelectAnyClientConfigEnabled ||
        clientSettings.Features.SelectHistoricClientConfigEnabled;

      if (canManageConfigs && !network.header?.configuration?.client) {
        getDefaultReference(token).then((p) => {
          loadNetwork(p);
          resolve();
        });
      } else if (
        canManageConfigs &&
        network.header?.configuration?.client &&
        (network.header.configuration.client !== reference.version.client ||
          network.header.configuration.version !== reference.version.number ||
          network.header.configuration.client === "AUTO")
      ) {
        if (network.header.configuration.client === "AUTO") {
          dispatch({
            form: "rawConfigCompressed",
            obj: {
              compressedData: network.header.configuration.embedded.compressedData,
            },
            type: "REPLACE_STATE",
          });
          translateConfigToUI(token, network.header.configuration.embedded.compressedData).then(
            (p) => {
              if (!p) {
                setInvalidConfig(true);
                resolve();
                return;
              }
              loadNetwork(p);
              resolve();
            },
          );
        } else {
          getSpecificReference(
            token,
            network.header.configuration.client,
            network.header.configuration.version,
          ).then((p) => {
            if (!p) {
              setInvalidConfig(true);
              resolve();
              return;
            }
            loadNetwork(p);
            resolve();
          });
        }
      } else {
        loadNetwork();
        resolve();
      }
    });
  };

  const fileUploadResetState = (hasErrors = true) => {
    const _toolState = toolState;
    resetNetworkToolState(_toolState);
    _toolState.showNearbyAssets.substations = false;
    _toolState.showResults = false;
    _toolState.assessmentRunning = false;
    _toolState.isLoading = false;
    if (!hasErrors) _toolState.errors = {};
    setToolState(_toolState);
    setFileToImportType(null);
    setLoadedNetwork("");
    setIsLoadingNetwork(false);
  };

  const importNetwork = (fileType) => {
    const files = document.getElementById("loadNetwork").files;

    if (files.length <= 0) {
      return false;
    }

    if (
      clientSettings.Features.TranslateWinDebutEnabled &&
      splitFilename(files[0].name).extension?.toLowerCase() === "wdf"
    ) {
      setWinDebutImportError('To import a .wdf file use "IMPORT WINDEBUT STUDY"');
      return;
    }

    const fr = new FileReader();

    async function importXml(e) {
      try {
        setIsLoadingNetwork(true);
        const token = await getToken(instance, accounts);
        const response = await translateXmlToStudy(
          token,
          JSON.stringify({ files: [{ filename: e.target.filename, contents: e.target.result }] }),
        );

        if (response.data.messages?.length > 0) {
          errorHandling(response.data);
          return;
        }
        if (response.data.network.messages?.length > 0) {
          errorHandling(response.data.network);
          return;
        }

        let currentReference = reference;

        if (response.data.configuration) {
          const isCurrentConfig =
            response.data.configuration.client === reference.version?.client &&
            response.data.configuration.version === reference.version?.number;

          if (!isCurrentConfig) {
            currentReference = await getSpecificReference(
              token,
              response.data.configuration.client,
              response.data.configuration.version,
            );

            dispatch({
              form: "reference",
              obj: currentReference,
              type: "REPLACE_STATE",
            });
          }
        }

        const updatedNetwork = createNetworkFromAssessmentResponse(
          currentReference,
          response,
          clientSettings.DynamicProperties,
        );
        const allNewGeometries = replaceNetworkState(updatedNetwork, true);
        centreNetworkOnMap(updatedNetwork, allNewGeometries, leafletMap);
        cleanseDownstreamFeederNumbers(updatedNetwork);
        detectAndConfigureRating(updateLoadedStudies, dispatchRedux, SUSTAINED);
      } catch (error) {
        errorHandling(error);
      } finally {
        fileUploadResetState();
      }
    }

    fr.onload = async (e) => {
      if (clientSettings.Features.TranslateSmallworldEnabled && fileType === "xml") {
        importXml(e);
      } else {
        const network = JSON.parse(e.target.result);

        const _toolState = { ...toolState };
        resetNetworkToolState(_toolState);
        _toolState.showNearbyAssets.substations = false;
        setToolState(_toolState);

        cleanseDownstreamFeederNumbers(network);

        await loadNetworkWithConfig(network);
      }
    };

    fr.filename = files.item(0).name;
    fr.readAsText(files.item(0));
    setLoadedNetwork("");
  };

  const importWinDebutNetwork = (config) => {
    const files = document.getElementById("loadWinDebutNetwork").files;

    if (files.length <= 0) {
      return false;
    }

    const fileData = {
      files: [],
    };

    async function doTranslate(config) {
      setIsLoadingNetwork(true);
      setWinDebutConfiguration(undefined);
      try {
        const isCurrentConfig =
          config !== "auto" &&
          config.clientName === reference.version?.client &&
          config.versionNumber === reference.version?.number;

        const token = await getToken(instance, accounts);
        function getConfig(token) {
          if (isCurrentConfig || config === "auto") {
            return Promise.resolve(reference);
          }
          return getSpecificReference(token, config.clientName, config.versionNumber);
        }

        let [response, configResponse] = await Promise.all([
          translateWinDebutToStudy(token, JSON.stringify(fileData), config),
          getConfig(token),
        ]);

        if (response.data.messages?.length > 0) {
          resetFileInput("loadWinDebutNetwork", "displayedWinDebutInput");
          errorHandling(response.data);
          fileUploadResetState();
          return;
        }

        if (!configResponse) {
          errorHandling({
            message: "There was a problem getting the configuration",
          });
          fileUploadResetState();
          return;
        }

        if (response.data.network.messages?.length > 0) {
          resetFileInput("loadWinDebutNetwork", "displayedWinDebutInput");
          errorHandling(response.data.network);
          fileUploadResetState();
          return;
        }

        if (config === "auto") {
          if (!response.data.customConfiguration) {
            errorHandling({
              message: "There was a problem getting the configuration",
            });
            fileUploadResetState();
            return;
          }
          configResponse = await translateConfigToUI(
            token,
            response.data.customConfiguration.rawConfigCompressed,
          );

          if (!configResponse) {
            errorHandling({
              message: "There was a problem getting the configuration",
            });
            fileUploadResetState();
            return;
          }
        }

        const updatedNetwork = createNetworkFromAssessmentResponse(
          configResponse,
          response,
          clientSettings.DynamicProperties,
        );
        updatedNetwork.runtime = { ...updatedNetwork.runtime, hideMap: true };
        const allNewGeometries = replaceNetworkState(updatedNetwork, true);
        centreNetworkOnMap(updatedNetwork, allNewGeometries, leafletMap);
        cleanseDownstreamFeederNumbers(updatedNetwork);
        detectAndConfigureRating(updateNetwork, dispatchRedux, SUSTAINED);

        if (!isCurrentConfig) {
          dispatch({
            form: "reference",
            obj: configResponse,
            type: "REPLACE_STATE",
          });
          if (config === "auto") {
            dispatch({
              form: "rawConfigCompressed",
              obj: {
                compressedData: response.data.customConfiguration.rawConfigCompressed,
              },
              type: "REPLACE_STATE",
            });
          }
        }
        fileUploadResetState(false);
      } catch (error) {
        resetFileInput("loadWinDebutNetwork", "displayedWinDebutInput");
        errorHandling(error);
        fileUploadResetState();
      }
    }

    for (const file of files) {
      const reader = new FileReader();

      reader.onload = function (event) {
        const fileContents = event.target.result;
        const fileInfo = {
          filename: file.name,
          contents: fileContents,
        };

        fileData.files.push(fileInfo);

        if (fileData.files.length === files.length) {
          doTranslate(config);
        }
      };

      reader.readAsText(file);
    }
  };

  const importWinDebutFileName = () => {
    const files = Array.from(document.getElementById("loadWinDebutNetwork").files);

    if (files.length === 0) {
      return;
    }

    if (files.length > 4) {
      setWinDebutImportError("You can only import up to 4 files");
      return;
    }

    if (!files.some((p) => splitFilename(p.name).extension?.toLowerCase() === "wdf")) {
      setWinDebutImportError("A .wdf file is required when importing a WinDebut network");
      return;
    }

    if (
      !files
        .map((p) => splitFilename(p.name).name)
        .every((p) => p === splitFilename(files[0]?.name).name)
    ) {
      setMismatchedWinDebutFiles(
        files.map((p) => splitFilename(p.name).fullName).map((p) => ({ code: p, description: p })),
      );
      return;
    }

    const fileTypes = validationRules["winDebutStudy"].fileTypes.split(", ");
    const hasInvalidFiles = validateFiles(files, fileTypes);
    if (hasInvalidFiles) {
      setWinDebutImportError(hasInvalidFiles);
      return;
    }

    importWinDebut();
  };

  const importWinDebut = () => {
    const files = Array.from(document.getElementById("loadWinDebutNetwork").files);

    const displayedInput = document.getElementById("displayedWinDebutInput");

    const firstFilename = splitFilename(files[0]?.name).name;

    const types = files.map((p) => splitFilename(p.name).extension).join(", ");

    displayedInput.value = `${firstFilename} (${types})`;

    if (isRagNetworkPopulated(formState.ragNetworks)) {
      setIsLoadedRAGPreventingImport(true);
      setWinDebutFileToImport(firstFilename);
    } else {
      if (networkChanged && !network.existing) {
        setWinDebutFileToImport(firstFilename);
      } else {
        importWinDebutFile(true, firstFilename);
      }
      clientSettings.Features.StudyBrowserTitleEnabled &&
        setBrowserTabTitle(clientSettings.General.DisplayName);
    }
  };

  const cancelImportWinDebut = () => {
    setWinDebutConfiguration(undefined);
    setMismatchedWinDebutFiles(undefined);
    setWinDebutImportError(undefined);
    setWinDebutFileToImport(null);
    const loadWinDebutNetwork = document.getElementById("loadWinDebutNetwork");
    if (loadWinDebutNetwork) {
      loadWinDebutNetwork.value = "";
    }
    const displayedWinDebutInput = document.getElementById("displayedWinDebutInput");
    if (displayedWinDebutInput) {
      displayedWinDebutInput.value = "";
    }
  };

  const importFileName = () => {
    const displayedInput = document.getElementById("displayedInput");
    const filePath = document.getElementById("loadNetwork").value;

    var filename = filePath.replace(/^.*[\\\/]/, "");
    displayedInput.value = filename;
    let fileType = "json";
    if (filename.endsWith(".json")) {
      filename = filename.slice(0, -5);
    } else if (filename.endsWith(".xml")) {
      filename = filename.slice(0, -4);
      fileType = "xml";
    }

    const rules = clientSettings.Features.TranslateSmallworldEnabled
      ? validationRules["smallWorldEnabled"]
      : validationRules["smallWorldDisabled"];
    const files = Array.from(document.getElementById("loadNetwork").files);
    const fileTypes = rules.fileTypes.split(", ");
    const hasInvalidFiles = validateFiles(files, fileTypes);
    if (hasInvalidFiles) {
      setImportError(hasInvalidFiles);
      resetFileInput("loadNetwork", "displayedInput");
      return;
    }

    if (isRagNetworkPopulated(formState.ragNetworks)) {
      setIsLoadedRAGPreventingImport(true);
      setFileToImport(filename);
      setFileToImportType(fileType);
    } else {
      if (networkChanged && !network.existing) {
        setFileToImport(filename);
        setFileToImportType(fileType);
      } else {
        importFile(true, filename, fileType);
      }
      clientSettings.Features.StudyBrowserTitleEnabled &&
        setBrowserTabTitle(clientSettings.General.DisplayName);
    }
  };

  const importSitePlanPDF = async () => {
    const _toolState = toolState;
    _toolState.mode = "select";
    _toolState.sitePlanImage = undefined;
    setToolState(_toolState);

    PDFJS.GlobalWorkerOptions.workerSrc = PDFJSWorker;

    const displayedInput = document.getElementById("importSitePlanPdf");
    const filePath = document.getElementById("loadSitePlanPdf").value;

    let filename = filePath.replace(/^.*[\\\/]/, "");

    displayedInput.value = filename;
    if (filename.toLowerCase().endsWith(".pdf")) {
      filename = filename.slice(0, -4);
    } else {
      _toolState.alert = buildErrorData([{ description: "File is not a PDF" }]);
      setToolState(_toolState);
      document.getElementById("importSitePlanPdf").value = "";
      document.getElementById("loadSitePlanPdf").value = "";
      return;
    }

    const files = document.getElementById("loadSitePlanPdf").files;
    await showPdf(files);
  };

  async function showPdf(files) {
    const file = files[0];
    const uri = URL.createObjectURL(file);
    let loadingTask = PDFJS.getDocument({ url: uri });
    await loadingTask.promise
      .then((result) => {
        renderPage(result);
        return result;
      })
      .catch((e) => {
        const _toolState = { ...toolState };
        _toolState.alert = buildErrorData([{ description: "PDF cannot load" }]);
      });
  }

  async function renderPage(sitePlan) {
    const fileName = document.getElementById("importSitePlanPdf").value;
    const canvas = document.createElement("canvas");
    canvas.setAttribute("className", "canv");

    var page = await sitePlan.getPage(1);
    var viewport = page.getViewport({ scale: 1 });
    const bearings = getBearings(viewport.width, viewport.height);
    const positions = getPositions(bearings);
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    var render_context = {
      canvasContext: canvas.getContext("2d"),
      viewport: viewport,
    };
    await page.render(render_context).promise;
    let sitePlanImage = canvas.toDataURL("image/png");

    const points = [0, 1, 2, 3].map((p) => flip(point(positions[p])));
    const units = { units: "metres" };

    const width = distance(points[0], points[1], units).toFixed(0);
    const height = distance(points[0], points[3], units).toFixed(0);

    const _toolState = { ...toolState };
    const _sitePlan = {
      id: uuid(),
      image: sitePlanImage,
      name: fileName,
      opacity: 0.75,
      uniformScaling: true,
      positions,
      width,
      height,
      rotation: 0,
    };
    _toolState.originalSitePlans.push({ ..._sitePlan });
    _toolState.sitePlans.push(_sitePlan);
    setToolState(_toolState);
    if (sitePlan.numPages > 1) {
      setMultiPagePDF(true);
    }
  }

  const importFile = (canProcess, filename, fileType) => {
    setIsLoadedRAGPreventingImport(false);

    if (canProcess) {
      setFileName(filename || fileToImport);
      setSelectedStudyId("");
      setDefaultsConfirmed(false);
      importNetwork(fileType ?? fileToImportType);
      isRagNetworkPopulated(formState.ragNetworks) && replaceRagNetworksState();
      dispatchRedux(setTitle(filename || fileToImport));

      const _toolState = toolState;
      _toolState.assessmentFailed = true;
      _toolState.showNearbyAssets.substations = false;
      setToolState(_toolState);
    }

    setFileToImport(null);

    document.getElementById("loadNetwork").value = "";
  };

  const getBearings = (width, height) => {
    const hypotenuse = Math.hypot(width, height);
    const sine = width / hypotenuse;
    const sinAngle = Math.asin(sine) * (180 / Math.PI);
    return [-Math.abs(sinAngle), sinAngle, 180 - sinAngle, -Math.abs(180 - sinAngle)];
  };
  const importWinDebutFile = (canProcess, filename, config) => {
    setIsLoadedRAGPreventingImport(false);

    if (canProcess) {
      if (!config) {
        setWinDebutConfiguration(filename || winDebutFileToImport);
        return;
      }

      setFileName(filename || winDebutFileToImport);
      setSelectedStudyId("");
      setDefaultsConfirmed(false);
      importWinDebutNetwork(config);
      isRagNetworkPopulated(formState.ragNetworks) && replaceRagNetworksState();
      dispatchRedux(setTitle(filename || winDebutFileToImport));

      const _toolState = toolState;
      _toolState.assessmentFailed = true;
      setToolState(_toolState);

      setWinDebutFileToImport(null);
      document.getElementById("loadNetwork").value = "";
    } else {
      cancelImportWinDebut();
    }
  };

  const getPositions = (bearings) => {
    const { lat, lng } = leafletMap.getCenter();
    var pt = point([lng, lat]);
    var distance = 100;

    return bearings.map((m) =>
      destination(pt, distance, m, {
        units: "meters",
      }).geometry.coordinates.reverse(),
    );
  };

  useEffect(() => {
    if (
      accounts.length > 0 ||
      (!stringToBoolean(REACT_APP_B2C) && !stringToBoolean(REACT_APP_B2B))
    ) {
      _loadFiles();
      _loadStudyFilters();
      clientSettings.Features.TransformerNameSearchEnabled && _loadTransformers();
    }
  }, [accounts]);

  useEffect(() => {
    if (assessmentRunning) {
      checkDefaults();
    }
  }, [assessmentRunning]);

  const setSelectMode = () => {
    setFaults([]);

    const _toolState = { ...toolState };
    _toolState.mode = "select";
    _toolState.activeTab =
      clientSettings.Features.ImpedanceFaultDetectionEnabled && _toolState.activeTab === tab.LOCATE
        ? tab.LOCATE
        : clientSettings.Features.CROWNEnabled
          ? tab.CROWN
          : tab.FILE;
    _toolState.activeTool = "0";
    _toolState.activeCategory = 0;
    _toolState.selectedTool = [0, 0, 0, 0, 0];
    _toolState.clickedAsset = null;

    setToolState(_toolState);
  };

  const replaceNetworkState = (network, loadedNetwork, newReference) => {
    const _formState = { ...formState };
    const hasOverrides =
      !!network.localOverrideConfig && !!Object.keys(network.localOverrideConfig).length;

    if (loadedNetwork) {
      dispatch({
        form: "localOverrideConfig",
        obj: network.localOverrideConfig,
        type: "REPLACE_STATE",
      });
      delete network.localOverrideConfig;

      setNewDefaults(network, newReference ?? reference);
    }

    const constraints = setConstraints(clientSettings, _formState, network);
    const network2 = constraints.network;

    store.dispatch(startBatchAction());
    try {
      dispatchRedux(replaceWelders(network2.welders));
      dispatchRedux(replaceMotors(network2.motors));
      dispatchRedux(replacePointOfConnections(network2.pointOfConnections));
      dispatchRedux(replaceTransformers(network2.transformers));
      dispatchRedux(replaceGroupedConnections(network2.groupedConnections));
      dispatchRedux(replaceNodes(network2.nodes));
      dispatchRedux(replaceConnectionPoints(network2.connectionPoints));
      dispatchRedux(replaceCables(network2.cables));
      if (loadedNetwork) {
        dispatchRedux(setHideMap(network2.runtime?.hideMap === true));
      }
      if (loadedNetwork || !networkChanged) {
        dispatchRedux(setAsSaved());
      }
    } finally {
      store.dispatch(endBatchAction());
    }

    if (loadedNetwork) {
      store.dispatch(clearHistoryAction());
    }

    const allNewGeometries = (network2.welders ?? [])
      .map((p) => p.geometry)
      .concat((network2.motors ?? []).map((p) => p.geometry))
      .concat((network2.pointOfConnections ?? []).map((p) => p.geometry))
      .concat((network2.transformers ?? []).map((p) => p.geometry))
      .concat((network2.groupedConnections ?? []).map((p) => p.geometry))
      .concat((network2.nodes ?? []).map((p) => p.geometry))
      .concat((network2.connectionPoints ?? []).map((p) => p.geometry))
      .concat((network2.cables ?? []).map((p) => p.geometry));

    _formState.network = {};
    _formState.network.existing = false;

    dispatch({
      form: "network",
      obj: _formState.network,
      type: "REPLACE_STATE",
    });

    if (loadedNetwork && !(newReference ?? reference).version?.isLatest) {
      setDiscardingOverrides(hasOverrides);
      setUpgradeConfig(true);
    }

    if (newReference) {
      dispatch({
        form: "reference",
        obj: newReference,
        type: "REPLACE_STATE",
      });
    }
    setFaults(constraints.constraints);

    return allNewGeometries;
  };

  const handleUpgrade = async (isConfirmed) => {
    if (isConfirmed) {
      setIsLoadingNetwork(true);
      const latestVersion = reference.version.latestVersionNumber;

      setUpgradeConfig(false);
      const token = await getToken(instance, accounts);
      getSpecificReference(token, reference.version.client, latestVersion).then((newReference) => {
        const upgradeWarnings = [];

        upgradeWarnings.push(...upgradeTransformers(newReference, transformers, dispatchRedux));
        upgradeWarnings.push(...upgradeCableTypes(newReference, cables, dispatchRedux));
        upgradeWarnings.push(...upgradeConsumers(newReference, transformers, dispatchRedux));
        upgradeWarnings.push(...upgradeConsumers(newReference, cables, dispatchRedux));
        upgradeWarnings.push(...upgradeConsumers(newReference, groupedConnections, dispatchRedux));
        upgradeWarnings.push(...upgradeFeederFuses(newReference, cables, dispatchRedux));
        upgradeWarnings.push(...upgradeNodeFuses(newReference, groupedConnections, dispatchRedux));

        if (clientSettings.Features.CostingEnabled) {
          upgradeWarnings.push(...upgradeGroundTypes(newReference, cables, dispatchRedux));
        }

        if (upgradeWarnings) {
          setUpgradeConfigWarnings(upgradeWarnings);
        }

        store.dispatch(clearHistoryAction());

        if (hasOverrides(localOverrideConfig)) {
          hideResults();

          dispatch({
            form: "localOverrideConfig",
            obj: null,
            type: "REPLACE_STATE",
          });
        }

        dispatch({
          form: "reference",
          obj: newReference,
          type: "REPLACE_STATE",
        });
        setIsLoadingNetwork(false);
      });
    } else {
      setUpgradeConfig(false);
    }
  };

  const replaceSitePlans = (sitePlans) => {
    const _toolState = { ...toolState };
    _toolState.sitePlans = sitePlans;

    setToolState(_toolState);
  };

  const onStudyPeriodChange = (selection) => {
    setStudyPeriod(selection);
  };

  const onWhosStudyChange = (selection) => {
    setWhosStudy(selection);
  };

  const onSelectedGroupChange = (selection) => {
    setSelectedGroup(selection);
    setSelectedArea("");
  };

  const onSelectedAreaChange = (selection) => {
    setSelectedArea(selection);
  };

  const checkDefaults = () => {
    if (checkForDefaults(allAssets) && !defaultsConfirmed) {
      setHasDefaults(true);
    } else {
      runAssessment();
    }
  };

  const runAssessment = () => {
    let warnings = {
      messages: [],
    };
    let errors = {
      messages: [],
    };

    for (var c of cables) {
      if (c.cableGroup === cable.GROUP_MAINS_UG || c.cableGroup === cable.GROUP_SERVICE_UG) {
        if (
          clientSettings.Features.CostingEnabled &&
          (c.status === statusConstants.NEW || c.status === statusConstants.REPLACEMENT)
        ) {
          const error = checkCableHasGroundTypeOverrides(c);
          if (!isEmpty(error)) {
            errors.messages.push(error);
          }
        }
        if (
          clientSettings.Features.CostingEnabled &&
          c.groundTypeOverrides &&
          c.groundTypeOverrides.length > 0
        ) {
          const error = checkCableLengthMatchesGroundTypeOverrides(c);
          if (!isEmpty(error)) {
            errors.messages.push(error);
          }
        }
      }
      if (c.cableGroup === cable.GROUP_MAINS_OH || c.cableGroup === cable.GROUP_SERVICE_OH) {
        const warning = checkOverheadLineLength(c, reference, clientSettings);
        if (!isEmpty(warning)) {
          warnings.messages.push(warning);
        }
      }
    }

    for (const t of transformers) {
      const warning = checkDesignVoltage(t);
      if (!isEmpty(warning)) {
        warnings.messages.push(warning);
      }
    }

    for (const c of groupedConnections) {
      if (!c.groupedConnectionPoints || !c.groupedConnectionPoints.length) {
        const error = validateLinkBoxCables(cables, c);
        if (!isEmpty(error)) {
          errors.messages.push(error);
        }
      }
    }

    if (errors.messages.length > 0) {
      errorHandling(errors);
      setToolStateToSelectMode();
      return;
    }

    if (warnings.messages.length > 0) {
      handleWarnings(warnings);
      setToolStateToSelectMode();
      return;
    }

    if (clientSettings.Features.AdmdAdjustmentEnabled) {
      let nonDefaultTypes = [];
      checkAdmdConsumerTypes(reference, transformers, nonDefaultTypes);
      checkAdmdConsumerTypes(reference, cables, nonDefaultTypes);
      checkAdmdConsumerTypes(reference, groupedConnections, nonDefaultTypes);

      if (nonDefaultTypes.length) {
        const consumerType =
          nonDefaultTypes.length === 1
            ? `one or more consumers of type '${nonDefaultTypes[0]}'`
            : "multiple consumer types";
        const _toolState = toolState;
        _toolState.admdWarning = {
          type: alert.ERROR,
          className: alert.WARNING,
          messages: [
            {
              level: alert.LEVEL_WARNING,
              description: `Network contains ${consumerType} which are of non-default size. Adjusting to match the ADMD curve may not produce values matching the default curve. Do you want to apply the adjustments?`,
            },
          ],
          isWarning: true,
        };
        setToolState(_toolState);
        return;
      }
    }

    continueAssessment(clientSettings.Features.AdmdAdjustmentEnabled);
  };

  const setToolStateToSelectMode = () => {
    setToolState({
      showResults: false,
      assessmentRunning: false,
      assessmentFailed: true,
      isLoading: false,
      mode: MODE_SELECT,
      activeTool: "",
      activeCategory: 0,
    });
  };

  const canContinueAssessmentWithAdmdResolved = (apply) => {
    canContinueAssessment(true, apply);
  };

  const canContinueAssessment = (canContinue, applyAdmd) => {
    const _toolState = toolState;
    _toolState.admdWarning = {};
    _toolState.warnings = {};

    if (canContinue) {
      _toolState.assessmentFailed = false;
      _toolState.isLoading = "Running assessment";
      _toolState.errors = {};
      continueAssessment(!!applyAdmd);
    }

    setToolState(_toolState);
  };

  const continueAssessment = async (applyAdmd) => {
    dispatchRedux(resolveAllDefaults());

    setSelectMode();
    setFaults([]);
    setToolState({
      isLoading: "Running assessment",
      errors: {},
      warnings: {},
      showResults: false,
      assessmentFailed: false,
      costingFailed: false,
    });
    dispatchRedux(clearResults(getResultProperties(clientSettings)));

    const requestData = {
      requestParameters: {},
      network: convertToCim(ratingType),
      assessmentOptions: {
        applyAdmdAdjustment: applyAdmd === true,
      },
      localOverrideConfig: localOverrideConfig,
    };

    if (reference.version.client === "AUTO" && rawConfigCompressed) {
      requestData.embeddedConfiguration = rawConfigCompressed.compressedData;
    } else if (reference.version) {
      requestData.configuration = {
        client: reference.version.client,
        version: reference.version.number,
      };
    }

    let success = false;
    const token = await getToken(instance, accounts);
    return postAssessments(token, JSON.stringify(requestData), {})
      .then((response) => {
        if (response.data.messages) {
          errorHandling(response.data);
          return;
        }

        if (response.data.network.messages) {
          var warnings = response.data.network.messages.filter((p) => p.level === "Warning");
          if (warnings) {
            setToolState({
              infoWarnings: warnings,
            });
          } else {
            setToolState({
              infoWarnings: [],
            });
          }
        }

        const updatedNetwork = createNetworkFromAssessmentResponse(
          reference,
          response,
          clientSettings.DynamicProperties,
        );
        replaceNetworkState(updatedNetwork, false);
        success = true;
      })
      .catch((error) => {
        errorHandling(error);
      })
      .finally(() => {
        if (success) {
          const mode =
            clientSettings.Features.AssessmentResultsPopupEnabled && popupResults
              ? "select-results"
              : "select";
          const itemIndex = toolbar[0].items.findIndex((item) => item.class === mode);

          setToolState({
            activeCategory: 0,
            activeTool: "0",
            mode: mode,
            selectedTool: [itemIndex, 0, 0, 0, 0],
            showResults: true,
            assessmentRunning: false,
            isLoading: false,
            skipAdmd: !applyAdmd,
          });
        } else {
          setToolState({
            showResults: true,
            assessmentRunning: false,
            isLoading: false,
            skipAdmd: !applyAdmd,
          });
        }
        setHasDefaults(false);
        setDefaultsConfirmed(false);
      });
  };

  const errorHandling = (error) => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const _toolState = toolState;
      _toolState.alert = buildErrorData([
        {
          description: `${error.response.status} - ${
            error.response.data.title ?? error.response.data
          }`,
        },
      ]);
      _toolState.assessmentFailed = true;
      setToolState(_toolState);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      const _toolState = toolState;
      _toolState.assessmentFailed = true;
      _toolState.alert = buildErrorData([{ description: error.request }]);
      setToolState(_toolState);
    } else if (error.messages) {
      const _toolState = toolState;
      if (error.messages.every((m) => m.level === "Warning")) {
        const data = buildWarningsData(error.messages);
        _toolState.alert = data;
        _toolState.errors = data;
      } else {
        const data = buildErrorData(error.messages);
        _toolState.alert = data;
        _toolState.errors = data;
      }
      _toolState.assessmentFailed = true;
      setToolState(_toolState);
    } else {
      // Something happened in setting up the request that triggered an Error
      const _toolState = toolState;
      _toolState.assessmentFailed = true;
      _toolState.alert = buildErrorData([
        { description: error.message || "An unexpected error occurred" },
      ]);
      setToolState(_toolState);
    }
  };

  const handleWarnings = (error) => {
    const _toolState = toolState;
    const data = buildWarningsData(error.messages);
    _toolState.warnings = data;
    _toolState.errors = data;
    _toolState.assessmentFailed = true;
    setToolState(_toolState);
  };

  const buildErrorData = (data) => {
    return {
      type: alert.ERROR,
      className: alert.DANGER,
      messages: data,
    };
  };

  const buildWarningsData = (data) => {
    return {
      type: alert.ERROR,
      className: alert.WARNING,
      messages: data,
      isWarning: true,
    };
  };

  const convertToCim = (ratingType) => {
    return createCimNetwork(
      reference,
      cables,
      [
        ...nodes,
        ...[...groupedConnections].filter(
          (gc) => !gc.groupedConnectionPoints || !gc.groupedConnectionPoints.length,
        ),
      ],
      connectionPoints,
      transformers,
      [...groupedConnections].filter(
        (gc) => gc.groupedConnectionPoints && gc.groupedConnectionPoints.length,
      ),
      motors,
      welders,
      pointOfConnections,
      ratingType,
    );
  };

  const onCheckboxBtnClick = (result) => {
    const _formState = { ...formState };
    _formState.clientSettings.filterResults.forEach((category) => {
      category.info.forEach((res) => {
        if (res.name === result.name) {
          res = result;
        }
      });
    });

    dispatch({
      form: "clientSettings",
      obj: _formState.clientSettings,
      type: "REPLACE_STATE",
    });
  };

  const saveFile = async (archiveExisting = false) => {
    setIsSaving(true);
    network.loaded = true;

    refreshNetworkConfig(network);

    const jsonRequest = {
      groupId: group,
      areaId: area,
      name: fileName,
      studyContent: JSON.stringify({ ...builtNetwork, ...network, localOverrideConfig }),
      archiveExisting,
      sitePlans: JSON.stringify(sitePlans),
    };

    setOverwriteStudy(null);
    setFileNameConflict(false);

    const token = await getToken(instance, accounts);
    return saveNetwork(token, jsonRequest)
      .then((response) => {
        setIsSaving(false);
        if (response.data.messages.length > 0) {
          switch (response.data.messages[0].code) {
            case "E200":
              if (clientSettings.Features.OverwriteStudyEnabled) {
                setOverwriteStudy(jsonRequest);
              } else {
                setFileNameConflict(true);
              }
              break;
            case "E210":
              setFileNameConflict(true);
              break;
            case "E218":
              setToolStateProp("alert", response.data.messages);
              break;
            default:
              break;
          }
        } else {
          if (response.data.studyId) {
            setLoadedNetwork(response.data.studyId);
          }
          setFileToLoad(false);
          network.existing = true;
          dispatchRedux(setAsSaved());
          if (response.data.studyItem) {
            updateLoadedStudies(response.data.studyId, response.data.studyItem);
          } else {
            _loadFiles();
          }
          clientSettings.Features.StudyBrowserTitleEnabled &&
            setBrowserTabTitle(clientSettings.General.DisplayName, fileName);
          dispatchRedux(setTitle(fileName));

          setToolStateProp("alert", [
            {
              description: `Study ${fileName} has been successfully saved.`,
            },
          ]);
        }
      })
      .catch((error) => {
        errorHandling(error);
      });
  };

  const archiveAndSaveFile = async (value) => {
    if (value && typeof value === "string") {
      await saveFile(true);
    } else {
      setArchiveFile("");
    }
  };

  const deleteFileModal = (e, studyName, studyId) => {
    e.stopPropagation();
    setFileToDelete({ studyName, studyId });
  };

  const loadFileModal = (studyId) => {
    if (selectedStudyId === studyId) {
      loadNetwork(studyId);
      return setSelectedStudyId(studyId);
    }

    if (isRagNetworkPopulated(formState.ragNetworks)) {
      setIsLoadedRAGPreventingLoad(true);
      setSelectedStudyId(studyId);
      setFileToLoad(studyId);
    } else {
      if (networkChanged && !network.existing) {
        setFileToLoad(studyId);
      } else {
        loadNetwork(studyId);
      }
    }
  };

  const deleteFile = async (studyData) => {
    if (studyData && studyData.studyId) {
      setIsDeleting(studyData.studyId);
      network.loaded = true;

      const token = await getToken(instance, accounts);
      return deleteNetwork(token, studyData.studyId)
        .then(() => {
          updateLoadedStudies(studyData.studyId);
        })
        .catch((error) => {
          errorHandling(error);
          _loadFiles();
        })
        .finally(() => {
          setIsDeleting(null);
          setFileToDelete(false);
          if (
            studyData.studyId === selectedStudyId &&
            clientSettings.Features.StudyBrowserTitleEnabled
          )
            setBrowserTabTitle(clientSettings.General.DisplayName);
        });
    } else {
      setFileToDelete(false);
    }
  };

  const archiveFileModal = (e, studyName, studyId) => {
    e.stopPropagation();
    setFileToArchive({ studyName, studyId });
  };

  const archiveFileSaveStudies = async (study) => {
    if (study.studyId) {
      setIsArchiving(study.studyId);
      network.loaded = true;

      const jsonRequest = true;

      const token = await getToken(instance, accounts);
      return archiveNetwork(token, study.studyId, jsonRequest)
        .then(() => {
          var archived = loadedFiles.studies.find((p) => p.studyId === study.studyId);
          if (archived) {
            archived.studyArchived = true;
          }
          updateLoadedStudies(study.studyId, archived);
        })
        .catch((error) => {
          errorHandling(error);
          _loadFiles();
        })
        .finally(() => {
          setIsArchiving(null);
          setFileToArchive(false);
          if (study.studyId === selectedStudyId && clientSettings.Features.StudyBrowserTitleEnabled)
            setBrowserTabTitle(clientSettings.General.DisplayName);
        });
    }
  };

  const overwriteExistingStudy = async (isOKButtonClicked) => {
    if (!isOKButtonClicked || !overwriteStudy) {
      setOverwriteStudy(null);
      return;
    }

    setIsSaving(true);

    const name = overwriteStudy.name;

    setOverwriteStudy(null);

    var study = getStudyByFileName(name);
    const studyId = study.studyId;
    setSelectedStudyId(studyId);

    network.loaded = true;
    network.existing = true;

    let jsonRequest = {
      studyContent: JSON.stringify({ ...builtNetwork, ...network }),
      sitePlans: JSON.stringify(sitePlans),
    };

    const token = await getToken(instance, accounts);
    return updateNetwork(token, studyId, jsonRequest)
      .then((response) => {
        setLoadedNetwork(studyId);

        if (response.data.studyItem) {
          updateLoadedStudies(studyId, response.data.studyItem);
        } else {
          _loadFiles();
        }
        clientSettings.Features.StudyBrowserTitleEnabled &&
          setBrowserTabTitle(clientSettings.General.DisplayName, study.studyName);
        setToolStateProp("alert", [
          {
            description: `Study ${name} has been successfully saved.`,
          },
        ]);
      })
      .catch((error) => {
        errorHandling(error);
        _loadFiles();
      })
      .finally(() => {
        setIsSaving(false);
        dispatchRedux(setAsSaved());
      });
  };

  const archiveFileAction = async (fileToArchive) => {
    if (fileToArchive) {
      await archiveFileSaveStudies(fileToArchive);
    }
    setFileToArchive(false);
  };

  const refreshNetworkConfig = (network) => {
    network.header = {
      application: clientSettings.General.DisplayName,
      version: clientSettings.General.Version,
      created: new Date(),
      configuration: {
        client: reference.version?.client,
        version: reference.version?.number,
        embedded: rawConfigCompressed,
      },
    };
  };

  const exportNetwork = () => {
    network.loaded = true;
    refreshNetworkConfig(network);

    const fileData = JSON.stringify({ ...builtNetwork, ...network, localOverrideConfig });
    const blob = new Blob([fileData], { type: "text/plain" });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // for IE
      window.navigator.msSaveOrOpenBlob(blob, `${fileName}.json`);
    } else {
      // for Non-IE (chrome, firefox etc.)
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.download = `${fileName}.json`;
      link.href = url;
      link.click();
      URL.revokeObjectURL(url);
      link.remove();
    }
    dispatchRedux(setTitle(fileName));
  };

  const exportPDF = () => {
    setLoadingPDF(true);

    const printArea = document.getElementById("content");
    toPng(printArea)
      .then((dataURL) => {
        const pdf = new jsPDF({
          format: "a4",
          unit: "mm",
          orientation: "l",
        });

        pdf.addImage(dataURL, "PDF", 20, 20, 257, 145);
        pdf.save(`${fileName}.pdf`);
        setLoadingPDF(false);
      })
      .catch((error) => {
        console.error("oops, something went wrong!", error);
        setLoadingPDF(false);
      });
  };

  const resolveDefaultsOrRunAssessment = (isOkButtonClicked) => {
    setHasDefaults(false);
    if (isOkButtonClicked) {
      setDefaultsConfirmed(true);
      runAssessment();
    } else {
      setToolState({
        assessmentRunning: false,
      });
      setDefaultsConfirmed(false);
    }
  };

  const replaceRagNetworksState = () => {
    const _formState = { ...formState };
    _formState.ragNetworks = [];
    dispatch({
      form: "ragNetworks",
      obj: _formState.ragNetworks,
      type: "REPLACE_STATE4",
    });
    dispatch({
      obj: _formState.ragNetworks,
      type: "SAVE_NETWORK",
    });
  };

  const sortFilteredStudyList = (custom, ascDesc) => {
    setSortByCustom(custom);
    setSortByAscDesc(ascDesc);
  };

  const changeNetwork = () => {
    setSelectedStudyId("");
    setLoadedNetwork("");
    setDefaultsConfirmed(false);
  };

  const disableAllWarningsButton = () => {
    return !toolState.showResults || faults.filter((p) => p.constraintCount > 0).length === 0;
  };

  const showAllWarnings = () => {
    const activeFaults = faults.filter((p) => p.constraintCount > 0).map((p) => p.value);

    const infos = [];
    filterResults.forEach((filterResult) => {
      filterResult.info.forEach((info) => {
        if (activeFaults.includes(info.value) || activeFaults.includes(info.season + info.value)) {
          if (!info.isSet) {
            info.isSet = true;
            infos.push(info);
          }
          return;
        } else if (info.options) {
          const option = info.options.find((p) => activeFaults.includes(p.value));
          if (option) {
            if (!info.isSet || info.value !== option.value) {
              info.value = option.value;
              info.isSet = true;
              infos.push(info);
            }
            return;
          }
        }
        if (info.isSet) {
          if (info.options) {
            info.value = undefined;
          }
          info.isSet = false;
          infos.push(info);
        }
      });
    });

    infos.forEach(onCheckboxBtnClick);
  };

  const updateOverallSeason = (season) => {
    const infos = [];
    filterResults.forEach((filterResult) => {
      filterResult.info
        .filter((info) => info.isSet && info.season && info.season !== season)
        .forEach((info) => {
          info.season = season;
          infos.push(info);
        });
    });

    infos.forEach(onCheckboxBtnClick);
  };

  const getOverallSeason = () => {
    const selectedSeasons = [
      ...new Set(
        filterResults
          .map((filterResult) =>
            filterResult.info
              .filter((info) => info.isSet && info.season)
              .map((info) => info.season),
          )
          .flat(),
      ),
    ];
    if (selectedSeasons.length === 1) {
      return selectedSeasons[0];
    }
    return null;
  };

  const hasSeasons = () => {
    return (
      filterResults
        .map((filterResult) =>
          filterResult.info.filter((info) => info.isSet && info.season).map((info) => info.season),
        )
        .flat().length > 0
    );
  };

  const validateWinDebutStudyImport = () => {
    return validationRules["winDebutStudy"];
  };

  const validateStudyImport = () => {
    return clientSettings.Features.TranslateSmallworldEnabled
      ? validationRules["smallWorldEnabled"]
      : validationRules["smallWorldDisabled"];
  };

  return (
    <>
      {hasDefaults && (
        <WarningModal
          item={true}
          action={resolveDefaultsOrRunAssessment}
          msg={
            "Please note - some properties have been set to suggested values. Do you want to accept all of these?"
          }
          dismissLabel="Cancel"
          yesLabel="OK"
        />
      )}
      {archiveFile && (
        <WarningModal
          item={archiveFile}
          action={archiveAndSaveFile}
          msg={`A network already exists with this name, do you want to Archive the existing network and overwrite or cancel?`}
          yesLabel="Archive and overwrite"
          dismissLabel="Cancel"
        />
      )}
      {overwriteStudy && (
        <WarningModal
          item={true}
          action={overwriteExistingStudy}
          msg={`A study already exists with this name, do you want to overwrite the existing study or cancel?`}
          yesLabel="Overwrite"
          dismissLabel="Cancel"
        />
      )}
      {fileToArchive && (
        <WarningModal
          item={fileToArchive}
          action={archiveFileAction}
          msg={`Archive ${fileToArchive.studyName} - are you sure?`}
          yesLabel="Archive"
          dismissLabel="Cancel"
        />
      )}
      {fileToDelete && (
        <WarningModal
          item={fileToDelete}
          action={deleteFile}
          msg={`Delete ${fileToDelete.studyName} - are you sure?`}
          dismissLabel="Cancel"
        />
      )}
      {(fileToLoad || isLoadedRAGPreventingLoad) && (
        <WarningModal
          item={fileToLoad}
          action={loadOrImportNetwork}
          msg={
            isLoadedRAGPreventingLoad
              ? `Clicking OK will clear the RAG network - are you sure?`
              : `Discard unsaved changes - are you sure?`
          }
          yesLabel={isLoadedRAGPreventingLoad ? "OK" : "Yes"}
          dismissLabel="Cancel"
        />
      )}
      {(fileToImport || winDebutFileToImport || isLoadedRAGPreventingImport) && (
        <WarningModal
          item={true}
          action={(canProcess, filename) =>
            winDebutFileToImport
              ? importWinDebutFile(canProcess, filename)
              : importFile(canProcess, filename)
          }
          msg={
            isLoadedRAGPreventingImport
              ? `Clicking OK will clear the RAG network - are you sure?`
              : `Discard unsaved changes - are you sure?`
          }
          yesLabel={isLoadedRAGPreventingImport ? "OK" : "Yes"}
          dismissLabel="Cancel"
        />
      )}
      {fileNameConflict && (
        <WarningModal
          item={fileNameConflict}
          action={() => setFileNameConflict(false)}
          msg={`A network already exists with this name, please use a unique name.`}
          dismissLabel="Cancel"
          hideYesButton={true}
        />
      )}
      {multiPagePDF && (
        <WarningModal
          item={multiPagePDF}
          action={() => setMultiPagePDF(false)}
          msg={`This PDF contains multiple pages. Only the first page is loaded.`}
          dismissLabel="OK"
          hideYesButton={true}
        />
      )}
      {importError && (
        <WarningModal
          item={importError}
          action={() => setImportError(false)}
          msg={importError}
          dismissLabel="OK"
          hideYesButton={true}
        />
      )}
      {winDebutImportError && (
        <WarningModal
          item={winDebutImportError}
          action={() => setWinDebutImportError(false)}
          msg={winDebutImportError}
          dismissLabel="OK"
          hideYesButton={true}
        />
      )}
      {mismatchedWinDebutFiles && (
        <WarningModal
          item={mismatchedWinDebutFiles}
          action={(isYes) => (isYes ? importWinDebut() : cancelImportWinDebut())}
          msg={`The files you have selected do not have a consistent name - are you sure you want to import them?`}
          messages={mismatchedWinDebutFiles}
          yesLabel="Yes"
          dismissLabel="Cancel"
        />
      )}
      {winDebutConfiguration && (
        <ConfigurationSelection
          selectConfig={(config) => importWinDebutFile(true, winDebutConfiguration, config)}
          autoDetect={() => importWinDebutFile(true, winDebutConfiguration, "auto")}
          cancel={() => cancelImportWinDebut()}
        />
      )}
      {invalidConfig && (
        <WarningModal
          msg={`Configuration settings not found. File cannot be loaded.`}
          action={() => setInvalidConfig(false)}
          dismissLabel="OK"
          hideYesButton={true}
          className="danger"
        />
      )}
      {upgradeConfig && (
        <WarningModal
          item={true}
          msg={
            "This network is using an older version of the configuration.  Would you like to upgrade it?" +
            (discardingOverrides
              ? "  This network has locally-defined configuration which will be discarded when you upgrade."
              : "")
          }
          action={(isYes) => handleUpgrade(isYes)}
        />
      )}
      {upgradeConfigWarnings.length > 0 && (
        <WarningModal
          action={() => setUpgradeConfigWarnings([])}
          msg={`The latest configuration version doesn't contain some of the options in this network, so they have been reverted to the defaults:`}
          messages={upgradeConfigWarnings.map((p, i) => {
            return { code: p.code, description: p.message };
          })}
          hideYesButton={true}
          dismissLabel="OK"
        />
      )}
      <ButtonGroup className="w-100 mb-4" size="sm" style={{ color: "#fff" }}>
        {clientSettings.Features.CROWNEnabled && (
          <Button
            id="btnCrownTab"
            color="sidebar-tabs"
            className={activeTab === tab.CROWN ? "active" : `btn-sidebar-tabs-${REACT_APP_THEME}`}
            onClick={() => {
              toggle(tab.CROWN);
            }}
          >
            CROWN
          </Button>
        )}
        <Button
          id="btnFileTab"
          color="sidebar-tabs"
          className={activeTab === tab.FILE ? "active" : `btn-sidebar-tabs-${REACT_APP_THEME}`}
          onClick={() => {
            toggle(tab.FILE);
          }}
        >
          File
        </Button>
        <Button
          id="btnPropertiesTab"
          color="sidebar-tabs"
          className={
            activeTab === tab.PROPERTIES ? "active" : `btn-sidebar-tabs-${REACT_APP_THEME}`
          }
          onClick={() => {
            toggle(tab.PROPERTIES);
          }}
        >
          Properties
        </Button>
        <Button
          id="btnResultsTab"
          color="sidebar-tabs"
          className={activeTab === tab.RESULTS ? "active" : `btn-sidebar-tabs-${REACT_APP_THEME}`}
          onClick={() => {
            toggle(tab.RESULTS);
          }}
        >
          Results{" "}
          {showResults && faults.filter((f) => f.constraintCount > 0).length > 0 && (
            <span className="badge badge-danger ml-1">!</span>
          )}
        </Button>
        {clientSettings.Features.ImpedanceFaultDetectionEnabled && (
          <Button
            id="btnLocateTab"
            color="sidebar-tabs"
            className={activeTab === tab.LOCATE ? "active" : `btn-sidebar-tabs-${REACT_APP_THEME}`}
            onClick={() => {
              toggle(tab.LOCATE);
            }}
          >
            Locate
          </Button>
        )}
        {clientSettings.Features.SearchElementEnabled && (
          <Button
            id="assetSearch"
            color="sidebar-tabs"
            className={
              activeTab === tab.ASSET_SEARCH ? "active" : `btn-sidebar-tabs-${REACT_APP_THEME}`
            }
            onClick={() => {
              toggle(tab.ASSET_SEARCH);
            }}
          >
            <FontAwesomeIcon icon={faSearch} />
          </Button>
        )}
      </ButtonGroup>
      <Scrollbars className="sidebar-scroll">
        <TabContent activeTab={activeTab} style={{ color: "#fff" }}>
          <TabPane tabId={tab.FILE}>
            {(clientSettings.Features.LocationSearchEnabled ||
              clientSettings.Features.LocationSearchAutoCompleteEnabled) && (
              <Row noGutters>
                <Col sm="12">
                  <div>
                    <ListGroupItem
                      className="bg-dark file-section text-uppercase"
                      onClick={() => {
                        setIsMapSearchOpen(!isMapSearchOpen);
                      }}
                      style={{ marginLeft: -10 }}
                    >
                      <span
                        style={{ width: 20, display: "inline-block" }}
                        id="mapSearchCollapseIcon"
                        className={`icon-chevron-${isMapSearchOpen ? "down" : "right"}`}
                      ></span>
                      Map Search
                    </ListGroupItem>
                  </div>
                  <Collapse isOpen={isMapSearchOpen === true}>
                    <MapSearch
                      autoComplete={clientSettings.Features.LocationSearchAutoCompleteEnabled}
                    />
                  </Collapse>
                </Col>
              </Row>
            )}
            {clientSettings.Features.NetworkSearchEnabled && (
              <Row noGutters>
                <Col sm="12">
                  <div>
                    <ListGroupItem
                      className="bg-dark file-section text-uppercase ml-n2"
                      onClick={() => {
                        setIsImportNetworkOpen(!isImportNetworkOpen);
                      }}
                    >
                      <span
                        style={{ width: 20, display: "inline-block" }}
                        id="importNetworkCollapseIcon"
                        className={`icon-chevron-${isImportNetworkOpen ? "down" : "right"}`}
                      ></span>
                      Import Network
                    </ListGroupItem>
                  </div>
                  <Collapse isOpen={isImportNetworkOpen}>
                    <>
                      <Searchbox loadedTransformers={loadedTransformers} />
                      <ShowNearbyAssetToggle type="substation" name="Substations" />
                    </>
                    <FeederFilter changeNetwork={changeNetwork} />
                  </Collapse>
                </Col>
              </Row>
            )}
            {clientSettings.Features.SitePlanEnabled && (
              <Row noGutters>
                <Col sm="12">
                  <div>
                    <ListGroupItem
                      className="bg-dark file-section text-uppercase ml-n2"
                      onClick={() => {
                        setIsImportSitePlanPdfOpen(!isImportSidePlanPdfOpen);
                      }}
                    >
                      <span
                        style={{ width: 20, display: "inline-block" }}
                        id="importSitePlanPdfCollapseIcon"
                        className={`icon-chevron-${isImportSidePlanPdfOpen ? "down" : "right"}`}
                      ></span>
                      Import Site Plan PDF
                    </ListGroupItem>
                  </div>
                  <Collapse isOpen={isImportSidePlanPdfOpen}>
                    <NetworkLoad
                      importFileName={importSitePlanPDF}
                      label="Import"
                      fileInputId="loadSitePlanPdf"
                      textInputId="importSitePlanPdf"
                      disabled={sitePlans.length > 0}
                    />
                  </Collapse>
                </Col>
              </Row>
            )}
            <Row noGutters>
              <Col sm="12">
                <div>
                  <ListGroupItem
                    className="bg-dark file-section text-uppercase ml-n2"
                    onClick={() => {
                      setIsImportStudyOpen(!isImportStudyOpen);
                    }}
                  >
                    <span
                      style={{ width: 20, display: "inline-block" }}
                      id="importStudyCollapseIcon"
                      className={`icon-chevron-${isImportStudyOpen ? "down" : "right"}`}
                    ></span>
                    Import Study
                  </ListGroupItem>
                </div>
                <Collapse isOpen={isImportStudyOpen}>
                  <NetworkLoad
                    importFileName={importFileName}
                    label="Import"
                    fileInputId="loadNetwork"
                    textInputId="displayedInput"
                    placeholder={validateStudyImport().placeholder}
                    allowedFileTypes={validateStudyImport().fileTypes}
                  />
                </Collapse>
              </Col>
            </Row>
            {clientSettings.Features.TranslateWinDebutEnabled && (
              <Row noGutters>
                <Col sm="12">
                  <div>
                    <ListGroupItem
                      className="bg-dark file-section text-uppercase ml-n2"
                      onClick={() => {
                        setIsImportWinDebutStudyOpen(!isImportWinDebutStudyOpen);
                      }}
                    >
                      <span
                        style={{ width: 20, display: "inline-block" }}
                        id="importWinDebutStudyCollapseIcon"
                        className={`icon-chevron-${isImportWinDebutStudyOpen ? "down" : "right"}`}
                      ></span>
                      Import WinDebut Study
                    </ListGroupItem>
                  </div>
                  <Collapse isOpen={isImportWinDebutStudyOpen}>
                    <NetworkLoad
                      importFileName={importWinDebutFileName}
                      label="Import"
                      fileInputId="loadWinDebutNetwork"
                      textInputId="displayedWinDebutInput"
                      placeholder={validateWinDebutStudyImport().placeholder}
                      multiple={true}
                      max={4}
                      allowedFileTypes={validateWinDebutStudyImport().fileTypes}
                    />
                  </Collapse>
                </Col>
              </Row>
            )}
            <Row noGutters>
              <Col sm="12">
                <div>
                  <ListGroupItem
                    className="bg-dark file-section text-uppercase ml-n2"
                    onClick={() => {
                      setIsSaveStudyOpen(!isSaveStudyOpen);
                    }}
                  >
                    <span
                      style={{ width: 20, display: "inline-block" }}
                      id="saveStudyCollapseIcon"
                      className={`icon-chevron-${isSaveStudyOpen ? "down" : "right"}`}
                    ></span>
                    Save Study
                  </ListGroupItem>
                </div>
                <Collapse isOpen={isSaveStudyOpen}>
                  <SavedStudies
                    userNameInput={userNameInput}
                    setUserNameInput={setUserNameInput}
                    area={area}
                    group={group}
                    loadingPDF={loadingPDF}
                    fileName={fileName}
                    studyFilters={studyFilters}
                    setFileName={setFileName}
                    setArea={setArea}
                    setGroup={setGroup}
                    saveFile={saveFile}
                    exportNetwork={exportNetwork}
                    exportPDF={exportPDF}
                    saveNetworkEnabled={clientSettings.Features.SaveNetworkEnabled}
                    isSaving={isSaving}
                  />
                </Collapse>
              </Col>
            </Row>
            <Row noGutters>
              <Col sm="12">
                <div className="d-flex justify-content-between bg-dark">
                  <div className="text-start">
                    <ListGroupItem
                      className="bg-dark file-section text-uppercase ml-n2 pr-1"
                      onClick={() => {
                        setIsLoadStudyOpen(!isLoadStudyOpen);
                      }}
                    >
                      <span
                        style={{ width: 20, display: "inline-block" }}
                        id="loadStudyCollapseIcon"
                        className={`icon-chevron-${isLoadStudyOpen ? "down" : "right"}`}
                      />
                      Load Study
                    </ListGroupItem>
                  </div>
                  {clientSettings.Features.StudySortEnabled && isLoadStudyOpen && (
                    <div className="text-end">
                      <SortList
                        customItems={getFileManagementSortItems()}
                        sortFilteredStudyList={sortFilteredStudyList}
                      />
                    </div>
                  )}
                </div>
                <Collapse isOpen={isLoadStudyOpen}>
                  {!areStudiesLoaded ? (
                    <div
                      className="container mt-3 text-muted d-flex justify-content-center"
                      style={{ fontSize: 14 }}
                    >
                      <i className="spinner icon-spinner-solid" />
                    </div>
                  ) : (
                    <>
                      <SavedStudiesFilter
                        studyPeriod={studyPeriod}
                        selectedGroup={selectedGroup}
                        selectedArea={selectedArea}
                        whosStudy={whosStudy}
                        studyFilters={studyFilters}
                        onStudyPeriodChange={onStudyPeriodChange}
                        onWhosStudyChange={onWhosStudyChange}
                        onSelectedAreaChange={onSelectedAreaChange}
                        onSelectedGroupChange={onSelectedGroupChange}
                        canArchive={clientSettings.Features.ArchiveStudyEnabled}
                      ></SavedStudiesFilter>

                      <SearchFile
                        loadedNetwork={loadedNetwork}
                        loadedFiles={getFilteredFiles()}
                        studyPeriod={studyPeriod}
                        loadNetwork={loadFileModal}
                        deleteFile={deleteFileModal}
                        archiveFile={archiveFileModal}
                        studyFilters={studyFilters}
                        saveNetworkEnabled={clientSettings.Features.SaveNetworkEnabled}
                        canArchive={clientSettings.Features.ArchiveStudyEnabled}
                        isArchiving={isArchiving}
                        isDeleting={isDeleting}
                      />
                    </>
                  )}
                </Collapse>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId={tab.CROWN}>
            <Row noGutters>
              <Col sm="12">
                <Crown />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId={tab.PROPERTIES}>
            <Row noGutters>
              <Col sm="12">
                <FormSwitcher />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId={tab.RESULTS}>
            <Row noGutters>
              <Col
                sm="12"
                style={{
                  fontSize: "0.875rem",
                  margin: "0.25rem 0.75rem 0.75rem",
                }}
              >
                <Button
                  size="sm"
                  color="danger"
                  name="show-warnings-button"
                  onClick={showAllWarnings}
                  disabled={disableAllWarningsButton()}
                >
                  Display All Over-Limit Values
                </Button>
                <span
                  style={{ cursor: "pointer", marginRight: "4.35rem" }}
                  name="overall-seasons"
                  className="float-right"
                >
                  {hasSeasons() && (
                    <IconRadioGrp
                      array={[
                        {
                          name: "summer",
                          color: "warning",
                          icon: "sun",
                        },
                        {
                          name: "winter",
                          color: "info",
                          icon: "snowflake",
                        },
                        {
                          name: "All",
                          color: "light",
                          icon: "all",
                        },
                      ]}
                      value={getOverallSeason()}
                      setValue={(e) => updateOverallSeason(e.season)}
                    />
                  )}
                </span>
              </Col>
            </Row>
            <Row noGutters>
              <Col sm="12" style={{ fontSize: "0.875rem" }}>
                {filterResults && (
                  <FaultFilter
                    faults={faults}
                    resultCategories={filterResults}
                    onCheckboxBtnClick={onCheckboxBtnClick}
                    defaultSeason={getOverallSeason()}
                  />
                )}
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId={tab.LOCATE}>
            <Row noGutters>
              <Col sm="12">
                <Detect />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId={tab.ASSET_SEARCH}>
            <Row noGutters>
              <Col sm="12">
                <div>
                  <ListGroupItem className="bg-dark file-section text-uppercase ml-n2">
                    Asset Search
                  </ListGroupItem>
                </div>
                <AssetSearch />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </Scrollbars>
      {!isEmpty(toolState.warnings) && (
        <WarningModal
          item={true}
          action={canContinueAssessment}
          msg={getWarningMessage(toolState.errors.messages, reference)}
          messages={toolState.errors.messages.length > 1 ? toolState.errors.messages : null}
          yesLabel="OK"
          dismissLabel="Cancel"
        />
      )}
      {!isEmpty(toolState.infoWarnings) && (
        <WarningModal
          item={true}
          action={() => setToolState({ infoWarnings: [] })}
          msg="The assessment has completed with the following warnings:"
          messages={toolState.infoWarnings}
          dismissLabel="OK"
          hideYesButton={true}
        />
      )}
      {!isEmpty(toolState.admdWarning) && (
        <WarningModal
          item={true}
          action={canContinueAssessmentWithAdmdResolved}
          messages={toolState.admdWarning.messages}
          yesLabel="Yes"
          dismissLabel="No"
        />
      )}
      {isLoadingNetwork && <Spinner>Loading network</Spinner>}
    </>
  );
};

export default ControlTabs;
